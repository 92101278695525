<template>
  <div>
    <div class="locale">
      <h2><a-button @click="$router.back()" shape="circle" icon="arrow-left"/>用户详情</h2>
      <ol>
        <li><router-link to="/">后台</router-link></li>
        <li><a href="javascript:;">用户</a></li>
        <li><a href="javascript:;">用户</a></li>
        <li>详情</li>
      </ol>
    </div>

    <div class="box">
<!--      <div class="box-head">-->
<!--        <h3>登录详情</h3>-->
<!--      </div>-->
      <div class="box-body">
        <table class="view">
          <tr>
            <th>编号：</th>
            <td>{{item.id}}</td>
            <th>状态：</th>
            <td>
              <label class="label" :class="[item.state.value]">{{item.state.label}}</label>
            </td>
            <td rowspan="3" class="preview">
                  <a :href="item.headimgurl" target="_blank">
                    <img v-if="item.headimgurl" alt="" class="useravatar" :src="item.headimgurl" />
                  </a>
            </td>
          </tr>
          <tr>
            <th>手机号：</th>
            <td>{{item.mobile}}</td>
            <th>昵称：</th>
            <td>{{item.nickname}}</td>
          </tr>
          <tr>
            <th>OpenID：</th>
            <td>{{item.openid}}</td>
            <th>性别：</th>
            <td>{{item.sex.label}}</td>
          </tr>
        </table>
      </div>
    </div>



    <div class="box">
     <div class="box-head">
       <h3>答卷列表</h3>
     </div>
      <div class="box-body">
          <a-empty v-if="papers.length == 0" />
          <table class="list" v-if="papers.length > 0">
          <tr>
            <th style="width: 120px;">#</th>
            <th>角色</th>
            <th style="width: 120px;">单题分值</th>
            <th style="width: 120px;">题量</th>
            <th style="width: 120px;">总得分</th>
            <th style="width: 120px;">状态</th>
          </tr>
          <tr v-for="item in papers">
            <td>{{item.id}}</td>
            <td>
              <div>{{item.role.name}}</div>
            </td>
            <td>
              <div>{{item.value}}</div>
            </td>
            <td>
              <div>{{item.count.answers}}</div>
            </td>
            <td>
              <div>{{item.score}}</div>
            </td>
            <td>
              <label class="label" :class="[item.state.value]">{{item.state.label}}</label>
            </td>
          </tr>
        </table>
      </div>
    </div>

  </div>
</template>


<script>
export default {
  name: 'SysOplogDetail',
  data() {
    return {
      item: {
        staff: {}, records: [], state:{},
        changed: new Date(), created: new Date()
      },
      papers: []
    }
  },
  mounted() {
    this.request();
  },

  methods: {
    request() {
      let id = this.$route.params.id;
      this.$get('/user/user/detail/' + id).then(res => {
        let ajax = res.data;
        if (ajax.code == 0 && ajax.data) {
          this.$util.timeFields(ajax.data.item);
          this.item = ajax.data.item;
          this.papers = ajax.data.papers;
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.useravatar {
  width: 64px;
}
</style>